<template>
  <div>
    <!-- VISTA 1 -->
    <div class="mediaplayer-filters">
      <div class="mediaplayer-filters__title">
        Estás viendo: <span class="mediaplayer-filters__title__sep"><br /></span>{{ currentPlaysCategory }}
      </div>
      <div class="mediaplayer-filters__subtitle">
        <span v-if="!areGoalsSelected && playInfo.mainCategoryName">({{ playInfo.mainCategoryName }})</span>
      </div>
      <div class="mediaplayer-filters__containerdata">
        <div class="mediaplayer-filters__player-play">
          <div class="mediaplayer-filters__player-play__player-imgs" :style="playerStyle">
            <div
              class="mediaplayer-filters__player-play__player-imgs__jersey"
              :style="'color:' + getColor(playInfo.teamId) + '; text-shadow: ' + getShadow(playInfo.teamId) + ';'"
            >
              {{ playInfo.jersey }}
            </div>
            <div class="mediaplayer-filters__player-play__player-imgs__country" :style="teamStyle" :key="teamLogoKey" />
          </div>
          <div class="mediaplayer-filters__player-play__info">
            <div class="mediaplayer-filters__player-play__info__name">{{ getPlayerName(playInfo.playerName) }}</div>
            <div class="mediaplayer-filters__player-play__info__data">
              {{ playInfo.teams }}
            </div>
            <div class="mediaplayer-filters__player-play__info__date">
              {{ playDate }}
            </div>
          </div>
        </div>
        <div class="mediaplayer-filters__player-cbutton">
          <div class="buttonblack-filter-mediaplayer" @click="$emit('openmodal')">
            Elegir <span class="buttonblack-filter-mediaplayer__bold">Jugador / Equipo</span>
          </div>
        </div>
      </div>
    </div>
    <!-- VISTA 1 -->
  </div>
</template>

<script>
export default {
  props: {
    currentPlaysCategory: {
      type: String,
      default: '',
    },
    playInfo: {
      type: Object,
      default: () => {
        return {
          date: '',
          playerName: '',
          playCategory: '',
          teams: '',
          playerPhoto: '',
          categoriesNames: '',
          mainCategoryName: '',
        };
      },
    },
    areGoalsSelected: {
      type: Boolean,
      default: true,
    },
    isRetroMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrayColors: {
        //liga mx
        1: { color: '#1B2956', shadow: '#FFFFFF' },
        2: { color: '#1B2956', shadow: '#FFFFFF' },
        4: { color: '#FFFFFF', shadow: 'transparent' },
        5: { color: '#FFFFFF', shadow: 'transparent' },
        7: { color: '#1B2956', shadow: '#FFFFFF' },
        8: { color: '#FFFFFF', shadow: 'transparent' },
        9: { color: '#1B2956', shadow: '#FFFFFF' },
        10: { color: '#1B2956', shadow: '#FFFFFF' },
        11: { color: '#FFC68F', shadow: 'transparent' },
        12: { color: '#FFFFFF', shadow: 'transparent' },
        13: { color: '#FFFFFF', shadow: 'transparent' },
        14: { color: '#1B2956', shadow: '#FFFFFF' },
        16: { color: '#FFFFFF', shadow: 'transparent' },
        17: { color: '#FFFFFF', shadow: 'transparent' },
        18: { color: '#FFFFFF', shadow: 'transparent' },
        173: { color: '#FFFFFF', shadow: 'transparent' },
        205: { color: '#147F55', shadow: '#FFFFFF' },
        3014: { color: '#FFFFFF', shadow: 'transparent' },
        //expan
        482: { color: '#1D1D1D', shadow: '#FFFFFF' }, //alebrijes
        3: { color: '#FFFFFF', shadow: 'transparent' }, //atalante
        3161: { color: '#1D1D1D', shadow: '#FFFFFF' }, //cancun
        908: { color: '#1B2956', shadow: '#FFFFFF' }, //cimarrones
        3162: { color: '#1B2956', shadow: '#FFFFFF' }, //morellia
        557: { color: '#1B2956', shadow: '#FFFFFF' }, //celaya
        554: { color: '#FFFFFF', shadow: 'transparent' }, //correaceminos
        195: { color: '#FFFFFF', shadow: 'transparent' }, //dorados
        757: { color: '#FFFFFF', shadow: 'transparent' }, //mineros
        3166: { color: '#FFC68F', shadow: 'transparent' }, //tabasco
        3328: { color: '#1B2956', shadow: '#FFFFFF' }, //rayados
        3163: { color: '#1B2956', shadow: '#FFFFFF' }, //tapatio
        3164: { color: '#FFFFFF', shadow: 'transparent' }, //tepatitaln
        3165: { color: '#FFFFFF', shadow: 'transparent' }, //tlaxcala
        1603: { color: '#1D1D1D', shadow: '#FFFFFF' }, //tampico
        235: { color: '#1D1D1D', shadow: '#FFFFFF' }, //udg
        592: { color: '#1B2956', shadow: '#FFFFFF' }, //tapachula
        //femenil
        2734: { color: '#1B2956', shadow: '#FFFFFF' }, //america
        2735: { color: '#FFFFFF', shadow: 'transparent' }, //atlas
        2978: { color: '#FFFFFF', shadow: 'transparent' }, //san luis
        2749: { color: '#FFFFFF', shadow: 'transparent' }, //xolos
        2737: { color: '#FFFFFF', shadow: 'transparent' }, //cruz azul
        3018: { color: '#FFFFFF', shadow: 'transparent' }, //juarez
        2736: { color: '#1B2956', shadow: '#FFFFFF' }, //chivas
        2738: { color: '#FFFFFF', shadow: 'transparent' }, //leon
        2740: { color: '#FFFFFF', shadow: 'transparent' }, //mazatlan
        2741: { color: '#1B2956', shadow: '#FFFFFF' }, //monterrey
        2742: { color: '#147F55', shadow: '#FFFFFF' }, //necaxa
        2743: { color: '#1B2956', shadow: '#FFFFFF' }, //pachuca
        2744: { color: '#1B2956', shadow: '#FFFFFF' }, //puebla
        2746: { color: '#FFFFFF', shadow: 'transparent' }, //queretaro
        2747: { color: '#1B2956', shadow: '#FFFFFF' }, //santos
        2748: { color: '#FFFFFF', shadow: 'transparent' }, //tigres
        2750: { color: '#FFFFFF', shadow: 'transparent' }, //toluca
        2745: { color: '#FFC68F', shadow: 'transparent' }, //pumas
      },
      arrayColorsRetro: {
        //liga mx
        // chivas
        1: { color: '#1B2A56', shadow: '#FFFFFF' },
        // america
        2: { color: '#1B2A56', shadow: '#FFFFFF' },
        // atlante
        3: { color: '#FFFFFF', shadow: 'transparent' },
        // atlas
        4: { color: '#FFFFFF', shadow: 'transparent' },
        // cruz azul
        5: { color: '#1B2A56', shadow: '#FFFFFF' },
        // jaguares
        6: { color: '#1d1d1d', shadow: '#ffffff' },
        // monterrey
        7: { color: '#1B2a56', shadow: '#FFFFFF' },
        // morelia - mazatlan
        8: { color: '#1d1d1d', shadow: '#ffffff' },
        // pachuca
        9: { color: '#1b2a56', shadow: '#FFFFFF' },
        // puebla
        10: { color: '#1B2a56', shadow: '#FFFFFF' },
        // pumas
        11: { color: '#ffc78f', shadow: 'transparent' },
        // queretaro
        12: { color: '#FFFFFF', shadow: 'transparent' },
        // san luis
        13: { color: '#FFFFFF', shadow: 'transparent' },
        // santos
        14: { color: '#1b2a56', shadow: '#FFFFFF' },
        // tigres
        16: { color: '#1b2a56', shadow: '#ffffff' },
        // tijuana
        17: { color: '#FFFFFF', shadow: 'transparent' },
        // toluca
        18: { color: '#FFFFFF', shadow: 'transparent' },
        // leon
        173: { color: '#FFFFFF', shadow: 'transparent' },
        // necaxa
        205: { color: '#137f54', shadow: '#FFFFFF' },
        // veracruz
        469: { color: '#1b2a56', shadow: '#FFFFFF' },
        // celaya
        557: { color: '#1b2a56', shadow: '#FFFFFF' },
        // juarez
        3014: { color: '#FFFFFF', shadow: 'transparent' },
        // toros neza
        3396: { color: '#1d1d1d', shadow: '#ffffff' },
        // tecos
        3397: { color: '#1b2a56', shadow: '#ffffff' },
      },
      teamLogoKey: 0,
    };
  },
  computed: {
    playDate() {
      return this.playInfo.date
        ? this.playInfo.date
            .split('-')
            .reverse()
            .join('-')
        : '';
    },
    playerStyle() {
      return `background-image: url(https://az755631.vo.msecnd.net/players-jerseys${this.isRetroMode ? '-retro' : ''}/${
        this.playInfo.teamId
      }.png)`;
    },
    teamStyle() {
      if (this.isRetroMode) {
        return `background-image: url(https://az755631.vo.msecnd.net/teams-provisional-retro/${this.playInfo.teamId}.png)`;
      }
      return `background-image: url(${this.playInfo.teamLogo})`;
    },
  },
  methods: {
    getColor(team) {
      if (team) {
        const teamAux = this.isRetroMode ? this.arrayColorsRetro[team] : this.arrayColors[team];
        if (teamAux) {
          return teamAux.color;
        }
        return '#ffffff';
      }
    },
    getShadow(team) {
      if (team) {
        const teamAux = this.isRetroMode ? this.arrayColorsRetro[team] : this.arrayColors[team];
        if (teamAux) {
          const shadow = teamAux.shadow;
          return (
            '2px 0 0 ' +
            shadow +
            ', -2px 0 0 ' +
            shadow +
            ', 0 2px 0 ' +
            shadow +
            ', 0 -2px 0 ' +
            shadow +
            ', 1px 1px ' +
            shadow +
            ', -1px -1px 0 ' +
            shadow +
            ', 1px -1px 0 ' +
            shadow +
            ', -1px 1px 0 ' +
            shadow +
            '; -webkit-text-shadow: 2px 0 0 ' +
            shadow +
            ', -2px 0 0 ' +
            shadow +
            ', 0 2px 0 ' +
            shadow +
            ', 0 -2px 0 ' +
            shadow +
            ', 1px 1px ' +
            shadow +
            ', -1px -1px 0 ' +
            shadow +
            ', 1px -1px 0 ' +
            shadow +
            ', -1px 1px 0 ' +
            shadow +
            '; -ms-text-shadow: 2px 0 0 ' +
            shadow +
            ', -2px 0 0 ' +
            shadow +
            ', 0 2px 0 ' +
            shadow +
            ', 0 -2px 0 ' +
            shadow +
            ', 1px 1px ' +
            shadow +
            ', -1px -1px 0 ' +
            shadow +
            ', 1px -1px 0 ' +
            shadow +
            ', -1px 1px 0 ' +
            shadow +
            '; -o-text-shadow: 2px 0 0 ' +
            shadow +
            ', -2px 0 0 ' +
            shadow +
            ', 0 2px 0 ' +
            shadow +
            ', 0 -2px 0 ' +
            shadow +
            ', 1px 1px ' +
            shadow +
            ', -1px -1px 0 ' +
            shadow +
            ', 1px -1px 0 ' +
            shadow +
            ', -1px 1px 0 ' +
            shadow +
            ';'
          );
        }
      }
    },
    getPlayerName(playerName) {
      if (playerName) {
        return playerName
          .split(',')
          .reverse()
          .join(' ');
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonblack-filter-mediaplayer {
  width: 324px;
  height: 50px;
  border-radius: 5.6px;
  background-color: #464646;
  font-family: 'Avenir-Regular';
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 50px;
  letter-spacing: -0.12px;
  text-align: center;
  color: #fff;
  &__bold {
    font-family: 'Avenir-Demi';
  }
  &:hover {
    cursor: pointer;
    background-color: #353333;
  }
  @media screen and (max-width: 768px) {
    width: 294px;
    height: 43px;
    line-height: 43px;
    font-size: 19px;
  }
  @media screen and (max-width: 653px) {
    width: 498px;
    height: 62px;
    font-size: 26px;
    line-height: 60px;
    margin-left: 26px;
    margin-top: 24px;
  }
  @media screen and (max-width: 553px) {
    width: 408px;
    height: 54px;
    font-size: 24px;
    line-height: 54px;
    margin-left: 18px;
  }
  @media screen and (max-width: 453px) {
    width: 331px;
    height: 54px;
    font-size: 20px;
    line-height: 54px;
    margin-left: 8px;
    margin-top: 20px;
  }
  @media screen and (max-width: 353px) {
    width: 265px;
    height: 54px;
    font-size: 20px;
    line-height: 54px;
    margin-left: 5px;
    margin-top: 20px;
  }
}
.mediaplayer-filters {
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: inline-block;
  margin-bottom: 50px;
  &__title {
    width: 100%;
    height: 58px;
    font-family: 'Avenir-Pro-Bold';
    font-size: 41px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 58px;
    letter-spacing: -0.23px;
    text-align: center;
    color: #3e3e3e;
    @media screen and (max-width: 768px) {
      height: 54px;
      line-height: 54px;
      font-size: 27px;
      text-align: center;
      border-bottom: none;
      padding-left: 5px;
    }

    @media screen and (max-width: 653px) {
      padding-left: 20px;
    }
    @media screen and (max-width: 553px) {
      padding-left: 15px;
    }
    @media screen and (max-width: 453px) {
      padding-left: 5px;
      line-height: 12px;
      padding-top: 8px;
      font-size: 24px;
    }
    &__sep {
      display: none;
      @media screen and (max-width: 453px) {
        display: block;
      }
    }
  }
  &__subtitle {
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-family: 'Avenir-Medium';
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    border-bottom: solid 1px #d3d3d3;
    @media screen and (max-width: 768px) {
      text-align: center;
      line-height: 12px;
      font-size: 17px;
    }
    @media screen and (max-width: 453px) {
      line-height: 32px;
      font-size: 14px;
    }
  }
  &__containerdata {
    width: 100%;
    height: 172px;
    margin-top: 30px;
    display: inline-flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      margin-top: 5px;
      height: 106px;
    }
    @media screen and (max-width: 653px) {
      height: 172px;
      margin-top: 10px;
      display: inline-block;
    }
    @media screen and (max-width: 553px) {
      display: inline-block;
      margin-top: 5px;
    }
  }
  &__player-play {
    width: 500px;
    height: 172px;
    display: inline-flex;
    @media screen and (max-width: 853px) {
      // height: 106px; aqui
      width: 410px;
    }
    @media screen and (max-width: 768px) {
      height: 106px;
      width: 320px;
    }
    @media screen and (max-width: 653px) {
      width: 553px;
      height: 172px;
    }
    @media screen and (max-width: 553px) {
      width: 453px;
      height: 106px;
    }
    @media screen and (max-width: 453px) {
      width: 353px;
    }
    @media screen and (max-width: 353px) {
      width: 280px;
    }
    &__player-imgs {
      width: 141px;
      height: 172px;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;
      margin-left: 10px;
      @media screen and (max-width: 853px) {
        width: 118px;
        height: 160px;
        margin-left: 5px;
      }

      @media screen and (max-width: 768px) {
        width: 105px;
        height: 106px;
        margin-left: 5px;
      }
      @media screen and (max-width: 653px) {
        width: 121px;
        height: 172px;
        margin-left: 25px;
      }

      @media screen and (max-width: 553px) {
        width: 105px;
        height: 106px;
        margin-left: 5px;
      }
      &__jersey {
        width: 68px;
        height: 62px;
        position: absolute;
        // color: white;
        font-size: 38px;
        text-align: center;
        line-height: 62px;
        left: 33px;
        top: 53px;
        font-family: 'Avenir-Demi';
        @media screen and (max-width: 853px) {
          width: 54px;
          height: 42px;
          left: 23px;
          top: 53px;
          line-height: 42px;
          font-size: 29px;
        }
        @media screen and (max-width: 768px) {
          width: 38px;
          height: 24px;
          left: 16px;
          top: 39px;
          line-height: 24px;
          font-size: 22px;
        }
        @media screen and (max-width: 653px) {
          width: 64px;
          height: 62px;
          line-height: 62px;
          left: 29px;
          top: 53px;
          font-size: 35px;
        }
        @media screen and (max-width: 553px) {
          width: 54px;
          height: 42px;
          left: 23px;
          top: 35px;
          line-height: 42px;
          font-size: 29px;
        }
        @media screen and (max-width: 453px) {
          width: 41px;
          height: 27px;
          left: 18px;
          top: 38px;
          line-height: 27px;
          font-size: 22px;
        }
        @media screen and (max-width: 353px) {
          width: 33px;
          height: 27px;
          left: 14px;
          top: 38px;
          line-height: 27px;
          font-size: 18px;
        }
      }
      &__country {
        width: 32px;
        height: 32px;
        position: absolute;
        bottom: 18px;
        left: 22px;

        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        @media screen and (max-width: 853px) {
          width: 32px;
          height: 32px;
          bottom: 28px;
          left: 12px;
        }
        @media screen and (max-width: 768px) {
          width: 28px;
          height: 28px;
          bottom: 12px;
          left: 6px;
        }
        @media screen and (max-width: 653px) {
          width: 32px;
          height: 32px;
          bottom: 28px;
          left: 18px;
        }
        @media screen and (max-width: 553px) {
          width: 32px;
          height: 32px;
          bottom: 0px;
          left: 13px;
        }
        @media screen and (max-width: 453px) {
          width: 29px;
          height: 29px;
          bottom: 12px;
          left: 8px;
        }
        @media screen and (max-width: 353px) {
          width: 24px;
          height: 24px;
          bottom: 19px;
          left: 7px;
        }
      }
    }
    &__info {
      width: 345px;
      height: 172px;
      margin-left: 34px;
      @media screen and (max-width: 953px) {
        margin-left: 24px;
      }
      @media screen and (max-width: 853px) {
        margin-left: 16px;
      }

      @media screen and (max-width: 768px) {
        margin-top: 2px;
        height: 100px;
        margin-left: 16px;
      }
      @media screen and (max-width: 653px) {
        margin-left: 34px;
      }
      @media screen and (max-width: 553px) {
        margin-left: 14px;
      }

      &__name {
        width: 100%;
        height: 45px;
        font-family: 'Avenir-Medium';
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 45px;
        text-align: left;
        letter-spacing: -0.18px;
        color: #3e3e3e;
        margin-top: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @media screen and (min-width: 870px) {
          max-width: 324px;
        }
        @media screen and (max-width: 853px) {
          height: 40px;
          font-size: 22px;
          margin-top: 15px;
        }
        @media screen and (max-width: 768px) {
          height: 33px;
          margin-top: 0px;
          font-size: 18px;
        }
        @media screen and (max-width: 653px) {
          height: 45px;
          margin-top: 20px;
          font-size: 28px;
        }
        @media screen and (max-width: 553px) {
          height: 33px;
          margin-top: 0px;
          font-size: 18px;
        }
        @media screen and (max-width: 353px) {
          width: 200px;
          height: 33px;
          margin-top: 0px;
          font-size: 16px;
        }
      }
      &__date {
        width: 100%;
        height: 45px;
        font-family: 'Avenir-Medium';
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 45px;
        text-align: left;
        letter-spacing: -0.18px;
        color: #3e3e3e;
        @media screen and (max-width: 853px) {
          height: 40px;
          font-size: 22px;
        }
        @media screen and (max-width: 768px) {
          height: 33px;
          font-size: 18px;
        }
        @media screen and (max-width: 653px) {
          height: 45px;
          font-size: 28px;
        }
        @media screen and (max-width: 553px) {
          height: 33px;
          font-size: 18px;
        }
      }
      &__data {
        width: 100%;
        height: 45px;
        font-family: 'Avenir-Medium';
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 45px;
        text-align: left;
        letter-spacing: -0.18px;
        color: #3e3e3e;
        @media screen and (max-width: 853px) {
          height: 40px;
          font-size: 22px;
        }
        @media screen and (max-width: 768px) {
          height: 33px;
          font-size: 18px;
        }
        @media screen and (max-width: 653px) {
          height: 45px;
          font-size: 28px;
        }
        @media screen and (max-width: 553px) {
          height: 33px;
          font-size: 18px;
        }
      }
    }
  }
  &__player-cbutton {
    width: 346px;
    height: 172px;
    @media screen and (max-width: 768px) {
      width: 304px;
      height: 106px;
    }
    @media screen and (max-width: 653px) {
      width: 553px;
    }
    @media screen and (max-width: 553px) {
      width: 453px;
    }
    @media screen and (max-width: 453px) {
      width: 353px;
    }
    @media screen and (max-width: 353px) {
      width: 280px;
    }
  }
}
</style>
